import Vue from 'vue'
import VueRouter from 'vue-router'

const DefaultContainer = () => import('@/components/DefaultContainer.vue');

const Home = () => import('@/views/HomeView.vue');
const About = () => import('@/views/AboutView.vue');
const OurProjects = () => import('@/views/OurProjects.vue');
const Blog = () => import('@/views/blog/Blog.vue');
const BlogList = () => import('@/views/blog/BlogList');
const Login = () => import("@/views/login/Login.vue");
const NotFound = () => import("@/views/404/NotFound.vue")

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "start",
    component: DefaultContainer,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/login-admin',
        name: 'login',
        component: Login
      },
      {
        path: '/about',
        name: 'About',
        component: About
      },
      // {
      //   path: '/projects',
      //   name: 'Projects',
      //   component: OurProjects
      // },
      {
        path: '/blogs',
        name: 'blogs',
        component: BlogList
      },
      {
        path: '/blog/:id',
        name: 'blog',
        component: Blog
      },
      {
        path: '/404',
        name: 'notFound',
        component: NotFound
      },
    ],
  },
  {
    path: "*",
    redirect: '/404',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
