import {getAllBlogs, getBlogWithId, saveDataBlog} from '@/config/use_cases/Blog';

const store = {
    namespaced: true,
    state: {
        blogs_list: [],
        blogs_by_id: {}
    },
    getters: {
    },
    mutations: {
        async saveBlogsList(state, data) {
            state.blogs_list = data;
            let blogs_id = {};
            data.forEach(element => {
                blogs_id[element.id] = element;
            });
            state.blogs_by_id = blogs_id;
        },
        saveSpecificBlog(state, data) {
            if(!data) {
                return;
            }
            state.blogs_by_id[data.id] = data;
        }
    },
    actions: {
        async getAllBlogsList({commit, state}){
            try {
                let blogs = await getAllBlogs();
                if(state.blogs_list.lenth == 0) {
                    return state.blogs_list;
                }
                await commit('saveBlogsList', blogs)
                return blogs;
            } catch (error) {
                console.error("Error: ", error);
                return [];
            }
        },
        async getBlogById({commit, state}, id) {
            let blog = state.blogs_by_id[id];
            if(blog) {
                return blog
            } else {
                try {
                    blog = await getBlogWithId(id);
                    await commit("saveSpecificBlog", blog);
                    return blog;
                } catch (error) {
                    console.error("Error: ", error);
                    return {}
                }
            }
        },
        async setBlog({}, blog) {
            try {
                let response = await saveDataBlog(blog);
                return response;
            } catch (error) {
                console.error("Error: ", error);
                return null;
            }
        }
    },
}

export default store;