import VueI18n from 'vue-i18n'
import Vue from 'vue';
import {en} from '@/lang/en-US.js';
import {es} from '@/lang/es-ES.js';
// let es = localStorage.getItem('es');

Vue.use(VueI18n);

/ * --------- Uso básico ------------- * /
export default new VueI18n({
 locale: 'es',
 messages : {
   en,
   es,
 }
});