import Vue from 'vue'
import Vuex from 'vuex'
import blogs from './blogs'
import {
  Login_Google,
  Logout,
  get_current_user
} from '@/config/use_cases/Login';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
    updateUserName(state, user_name) {
      state.user_name = user_name;
    },
  },
  actions: {
    async getCurrentUser(){
      let user = await get_current_user();
      return user;
    },
    async login({
      commit
    }) {
      let data = await Login_Google();
      if (data) {
        this._vm.$session.set('user_name', data.displayName);
        await commit('updateUserName', data.displayName);
      }
      return data;
    },
    async logout_session({
      commit
    }) {
      let result = Logout();
      if (result) {
        this._vm.$session.set('user_name', '');
        await commit('updateUserName', '');
      }
    },

  },
  modules: {
    blogs
  }
})
