let es = {
    meta_data: {
      home_title: "AlianzaSoft",
      description: "Servicio de desarrollo de software a medida."
    },
    header: {
        icon: "https://firebasestorage.googleapis.com/v0/b/muy-hambriento.appspot.com/o/logo%20png.png?alt=media&token=08a237e7-0590-4e05-adc5-6bff1cf1b067",
        title: " Alianzasoft",
        button_text: "Sobre Nosotros"
    },
    footer: {
      title: "Alianzasoft",
      social_media: [
        {
            icon: 'mdi-facebook',
            color: '',
            url: 'https://www.facebook.com/alianzasoft'
        },
        {
            icon: 'mdi-whatsapp',
            color: '',
            url: 'https://api.whatsapp.com/send?phone=+529811691748'
        },
        {
            icon: 'mdi-phone',
            color: '',
            url: 'tel:+529811691748'
        },
        {
            icon: 'mdi-instagram',
            color: '',
            url: 'https://www.instagram.com/eduardo_castillo_caballero?igsh=ZzE5dTd3ejVzbXk='
        },
        // {
        //     icon: 'mdi-twitter',
        //     color: '',
        //     url: ''
        // }
    ]
    },
    general: {
      phone_number: "+529811691748",
      loading: "Espere un momento por favor...",
      page_not_found: "Lo sentimos, pagina no encontrada",
      image_pnf: "https://firebasestorage.googleapis.com/v0/b/alianzasoft-landingpage.appspot.com/o/20240508_155439.jpg?alt=media&token=e745eef1-7fec-4e7b-9197-ca5fa03988e1"
    },
    blog: {
      more: "Leer mas",
      create: "Crear Nuevo Blog",
      no_blog: "No se encontro contenido para este blog, si lo desea, puede crear un nuevo blog con el siguiente Id:",
      edit: "Editar blog:",
      cancel_edit: "Cancelar edicion",
      add_p: "Añadir parrafo",
      delete_last_p: "Eliminar ultimo parrafo",

    },
    login: {
      gmail: "Iniciar seIniciar con GMAIL",
      facebook: "Iniciar con Facebook",
      welcome: "Bienvenido",
      log_out: "Cerrar sesión",
      image: "https://firebasestorage.googleapis.com/v0/b/alianzasoft-landingpage.appspot.com/o/alianza.png?alt=media&token=ed069c04-9b41-4bdd-bfb2-3df17abafd68"
    },
    home: {
        carousel: [
            "https://firebasestorage.googleapis.com/v0/b/alianzasoft-landingpage.appspot.com/o/1000024258-transformed.jpeg?alt=media&token=325fa24c-d575-412c-a27c-c7bc26563d8c"
        ],
        logo: "https://firebasestorage.googleapis.com/v0/b/muy-hambriento.appspot.com/o/logo%20png.png?alt=media&token=08a237e7-0590-4e05-adc5-6bff1cf1b067",
        title: "Implementacion de proyectos TI",
        subtitle: "Desde el analisis hasta la puesta a producción.",
        p_1: "Realizamos análisis de sus requerimientos y proponemos solución de acuerdo a su infraestructura que requieran o con la que cuenten, tenemos implementación desde sistemas onpremises hasta sistemas basado 100% en la nube.",
        p_2: "Colaboramos con otras empresas de desarrollo de software realizando tareas de desarrollos de subproyecto o implementaciones muy específicas en las cuales requieren nuestro apoyo.",
        button: "Acerca de Nosotros",
        presentation: [
            [
              {
                background_color: "#ff5b4f",
                icon: "mdi-react",
                title: "Microservicios",
                subtitle:
                  "Desarrollo de microservicios  basado en tecnologia que se requiera como java, python, notejs."
              },
              {
                background_color: "#ff5b4f",
                icon: "mdi-docker",
                title: "Docker",
                subtitle:
                  "Integracion de aplicaciones mediente tecnologia docker y docker-compose segun las necesidades del cliente."
              }
            ],
            [
              {
                background_color: "#595d8f",
                icon: "mdi-database",
                title: "Bases de datos",
                subtitle:
                  "Servicio de administración de  base de datos así como servidores.  Diseñamos y apoyamos a la migración de distintos motores de base de datos manteniendo la integridad de su información. "
              },
              {
                background_color: "#595d8f",
                icon: "mdi-calendar-range-outline",
                title: "Migración  de datos",
                subtitle:
                  "Mediante la aplicacion de distintas tecnologias se realiza  la limpieaza, tramiento y carga de informacion de distintas fuentes para concentrase en un solo destino. "
              }
            ],
            [
              {
                background_color: "#8fcec5",
                icon: "mdi-cloud",
                title: "Desarrollo en la nube",
                subtitle:
                  "Realizamos desarrollo de aplicaciones en la nube de aws así como la integración de distintos servicios según las necesidades. "
              },
              {
                background_color: "#8fcec5",
                icon: "mdi-cloud-upload-outline",
                title: "Desarrollo sin servidor",
                subtitle:
                  "Desarrollo e implementación de funciones o servicios donde no se tinen la disposicion de servidores dedicados, para eso  apoyamos en la migración y administracion de funciones lambdas en aws."
              }
            ]
          ],
        our_clients: "Nuestros Clientes",
        info_clients: [
            {
                image: "https://firebasestorage.googleapis.com/v0/b/alianzasoft-landingpage.appspot.com/o/customers%2FLogo-Smapac-footer.png?alt=media&token=e3c6dd5f-63d1-493e-9e43-94eedb9989c2",
                title: "Smapac Campeche",
                text: "Desarrollo y soporte de sistema principal para cobros de servicios de agua potable y alcantarillado, soporte a  nuevos requerimientos e implementación en la nube así como la administración de servidores.",
            },
            {
                image: "https://firebasestorage.googleapis.com/v0/b/alianzasoft-landingpage.appspot.com/o/customers%2Fstrategiatrade.webp?alt=media&token=7f8d4377-7b95-46d2-b33f-8427f7412369",
                title: "XML procesor Strategiatrade",
                text: "Sistema para procesamiento de xml y conversión  a formato principal para alimentar sistemas globales de facturación para la empresa.",
            },
            {
                image: "https://firebasestorage.googleapis.com/v0/b/alianzasoft-landingpage.appspot.com/o/customers%2Francho.webp?alt=media&token=e052060d-16ef-45c2-aed9-9550142536e7",
                title: "Escarcega 1 Rancho Grande",
                text: "Sistema  para administracion de ventas de terrenos y seguimientos de pagos, basado en la nube de aws.",
            },
            {
                image: "https://firebasestorage.googleapis.com/v0/b/alianzasoft-landingpage.appspot.com/o/customers%2Fdescargar-h-6LgP8d1-transformed.png?alt=media&token=077957fc-d344-47e2-b2aa-a8653e3ab57a",
                title: "Siapac Tepic",
                text: "Desarrollo  Personalizado para administración  de servicio  de agua potable para el municipio de Tepic Nayarit.",
            },
            {
                image: "https://firebasestorage.googleapis.com/v0/b/alianzasoft-landingpage.appspot.com/o/customers%2Fag3-logoHD.webp?alt=media&token=8ff07fcf-0b22-4237-b36a-e664f56c7152",
                title: "AGT luxury travel Transporter",
                text: "Sistema para la administración de servicios de transportes  turísticos  en la zona de cancún y la riviera maya.",
            }            
        ]
    },
    about_us: {
        background_image: "https://firebasestorage.googleapis.com/v0/b/alianzasoft-landingpage.appspot.com/o/_f552a441-be19-406e-bd3d-8e9094dd7551-transformed.jpeg?alt=media&token=aa85fec0-ba70-40bb-83fc-5bf59ee331c7",
        title: "Acerca de Nosotros",
        sub_title: "Desarrollo de software a tu medida",
        technologies: [
            "mdi-react",
            "mdi-aws",
            "mdi-nodejs",
            "mdi-language-python",
            "mdi-language-html5",
            "mdi-language-css",
            "mdi-language-javascript",
            "mdi-language-php",
            "mdi-language-typescript",
            "mdi-language-java",
            "mdi-vuejs",
            "mdi-language-go"
        ],
        about_us: "¿Quienes somos?",
        description: "Somos un equipo de profesionales outsourcing especializados en distintas áreas del desarrollo de software, que apoyamos a empresas a cumplir sus proyectos de tecnologías. <br><b>Contamos con 10 años de experiencia en desarrollo e implementacion de sistemas a la medida.!</b><br> Nuestras principales funciones se basan en el BackEnd con conocimiento en distintas tecnologías, integrando a nuestros elementos en distintos equipos externos para brindar apoyo en los proyectos destinados. Nos especializamos en el desarrollo de apis que cumplan con los requerimientos proporcionados por el cliente.",
        image_info: "https://www.ado.com.mx/file/general/acerca_viaje.png",
        images_info: [
            "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Coat_of_arms_of_Mexico.svg/320px-Coat_of_arms_of_Mexico.svg.png",
            "https://firebasestorage.googleapis.com/v0/b/alianzasoft-landingpage.appspot.com/o/alianza.png?alt=media&token=ed069c04-9b41-4bdd-bfb2-3df17abafd68",
            "https://pbs.twimg.com/media/EF0NL8rXkAAhTL2.png"
        ]
    }
}

export {
    es
}