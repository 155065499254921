import {
    auth,
    db
} from '@/firebase';
import {
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
} from 'firebase/auth';
import {
    USERS
} from '@/config/data/parameters';
import {
    getDoc,
    setDoc,
    doc
} from "firebase/firestore";
async function get_current_user(){
    let user = await auth.currentUser;
    return user
}

async function Login_Google() {
    try {
        const provider = new GoogleAuthProvider();
        let result = await signInWithPopup(auth, provider);
        GoogleAuthProvider.credentialFromResult(result);
        const user = result.user;
        const docRef = doc(db, USERS, user.uid);
        let data = await getDoc(docRef);
        if (!data.exists()) {
            setDoc(doc(db, USERS, user.uid), {
                name: user.displayName,
                email: user.email
            });
        }
        return user;
    } catch (error) {
        console.error("Error: ", error);
        return null;
    }
}


async function Logout(){
    try {
        await signOut(auth);
        return true;
    } catch (error) {
        return false;
    }
}

export {
    Login_Google,
    Logout,
    get_current_user
}