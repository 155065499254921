import { collection, setDoc, getDocs, getDoc, doc } from "firebase/firestore";
import { HOME } from "@/config/data/parameters.js";
import {
    db
} from '@/firebase';

async function getAllBlogs() {
    try {
        let blogs_result = await getDocs(collection(db,HOME));
        let result = [];
        blogs_result.forEach(doc => {
            result.push(doc.data());
        })
        return result;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

async function getBlogWithId(id) {
    try {
        let blog = await getDoc(doc(db, HOME, id));
        return (await blog).data();
    } catch (error) {
        console.error("Error: ", error)
        return null
    }
}

async function saveDataBlog(blog) {
    try {
        await setDoc(doc(db, HOME, blog.id), blog);
        return true;
    } catch (error) {
        console.error("Error: ", error)
        return false;
    }
}

export {
    getAllBlogs,
    getBlogWithId,
    saveDataBlog
}