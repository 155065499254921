<template>
  <v-app id="inspire">
    <router-view ></router-view>
  </v-app>
</template>
<script>
  export default {
    name: "App",
    metaInfo: {
    titleTemplate: "AlianzaSoft",
    },
  }
</script>
<style>
.title_home {
  font-size: 40px;
  font-weight: 600;
  color: #595d8fff;
}
.subtitle_home {
  font-size: 24px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #FF5B4F !important;
}
.parrafo_home {
  font-size: 16px;
  font-weight: 400;
  color: #777777ff !important;
}
@media screen and (max-width: 959px) {
  .title_home {
    text-align: center;
  }
  .subtitle_home {
    text-align: center;
  }
  .parrafo_home {
    text-align: center;
  }
}
</style>